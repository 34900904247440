import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Checkbox, Dialog } from 'sw-ui';

import AjaxButton from '../../../../../components/ajaxButton';
import CommentComponent from '../../../../../components/Comment';
import NoResult from '../../../../../components/NoResult';
import UserEditDialog from './userEditDialog';
import UserSettingsDialog from './userSettingsDialog';

import { updatedUsers } from '../../../../../bi/utils/users';

import COMPONENTS from '../../../../../bi/constants/components';
import { STATUS, TYPEICONLIST, COMMENTS } from '../../../../../bi/constants/account';
import USERS_STATUS from '../../../../../bi/constants/user';
import { STEPSRIGHTS } from '../../../../../bi/constants/rights';

import styles from '../../styles/account.module.scss';

const DIALOG_WIDTH = {
  SMALL: '400px',
  LARGE: '600px',
};

const LABELS = {
  TOOLTIP: {
    COMMENT: 'Отправлять счета за абонентскую плату и уведомления о задолженности или низком балансе',
    SEND_DOC: 'У вас нет прав на эту настройку',
    APPLICATION_FORM_1C: 'разблокировать вкладку заявки(1С)',
  },
  TOOLTIPCOMMENT: 'Подробнее',
  BUTTONS_USER_DIALOG: {
    CONFIRM: 'Подтвердить',
    PROCEED: 'Продолжить',
    CANCEL: 'Отмена',
    DISABLE_RECOVERABLE: 'Отключить с возможностью восстановления',
    DISABLE_FOREVER: 'Отключить без возможности восстановления',
  },
  ERROR_USER_SAVE: 'Не удалось сохранить изменения. Обратитесь в поддержку',
};

const TEXT_FOR_DIALOGS = {
  DISABLED_ADMIN: eml => `Администратор ${eml} будет отключен.`,
  RIGHTS_ADMIN_MOVED: 'Права администратора будут установлены на',
  DO_YOU_CONFIRM: 'Вы подтверждаете действие?',
  SELECT_AN_ACTION: 'Выберите действие для текущего администратора',
  FOR_SCHEME: {
    ATTENTION: 'Внимание!',
    TO_USER: eml => `Пользователь ${eml} указан в качестве согласующего в правах пользователей!`,
    TO_USER_FOR_SCHEME: eml => `Пользователь ${eml} указан в качестве согласующего в схеме согласования!`,
    TO_ADMIN: eml => `Аминистратор ${eml} указан в качестве согласующего в правах пользователей!`,
    TO_ALL: eml => `${TEXT_FOR_DIALOGS.FOR_SCHEME.TO_USER(eml)} ${TEXT_FOR_DIALOGS.FOR_SCHEME.TO_USER_FOR_SCHEME(eml)} `,
  },
};

class UserItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    isActiveUser: PropTypes.bool.isRequired,
    userSettings: PropTypes.object.isRequired,
    userCompanies: PropTypes.array.isRequired,
    loadingUserSettings: PropTypes.bool.isRequired,
    companyService: PropTypes.object.isRequired,
    enabled: PropTypes.object.isRequired,
    waitingResponseIds: PropTypes.array.isRequired,
    userActiveData: PropTypes.object.isRequired,
    adminChange: PropTypes.bool.isRequired,
    accessSettingsChange: PropTypes.bool.isRequired,
    onClickOnUser: PropTypes.func.isRequired,
    getUserSettings: PropTypes.func.isRequired,
    getUserCompanies: PropTypes.func.isRequired,
    getDisabledUserSettings: PropTypes.func.isRequired,
    onSendNotification: PropTypes.func.isRequired,
    onSaveComment: PropTypes.func.isRequired,
    onClickRegistrationLink: PropTypes.func.isRequired,
    onEditUserDialog: PropTypes.func.isRequired,
    onChangeUsersData: PropTypes.func.isRequired,
    onSaveUserData: PropTypes.func.isRequired,
    onShowOfRightsDialog: PropTypes.func.isRequired,
    onNextTapDisableOfRights: PropTypes.func.isRequired,
    onCancelOfRightsDialog: PropTypes.func.isRequired,
    onSetOfRightsDialog: PropTypes.func.isRequired,
    getDataUserApproveScheme: PropTypes.func.isRequired,
    onSaveErrorSetRightUser: PropTypes.func.isRequired,
    onSendUserDoc: PropTypes.func.isRequired,
    onChangeUserSettings: PropTypes.func.isRequired,
    onChangeArraySettings: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    clearUserSettings: PropTypes.func.isRequired,
  };

  state = {
    editable: false,
    readOnly: false,
    userSettingsFlag: false,
    waitingResponse: false,
  };

  getNameForUser = () => {
    const { userActiveData: { userApproveScheme: { ByDirect, ByScheme } }, item: { Email } } = this.props;

    if (ByDirect && ByScheme) {
      return TEXT_FOR_DIALOGS.FOR_SCHEME.TO_ALL(Email);
    }

    if (ByDirect) {
      return TEXT_FOR_DIALOGS.FOR_SCHEME.TO_USER(Email);
    }

    return TEXT_FOR_DIALOGS.FOR_SCHEME.TO_USER_FOR_SCHEME(Email);
  };

  handleShowUserSettings = () => {
    const { userSettingsFlag } = this.state;
    const {
      isActiveUser,
      getUserSettings,
      getUserCompanies,
      clearUserSettings,
      item: {
        Id,
        IsPrimaryAccount,
      },
    } = this.props;

    if (isActiveUser && !userSettingsFlag) {
      getUserSettings(Id);

      if (!IsPrimaryAccount) getUserCompanies(Id);
    }

    if (userSettingsFlag) clearUserSettings();

    this.setState({
      userSettingsFlag: !userSettingsFlag,
    });
  }

  handleUpdateUserSettings = () => {
    const { updateUserSettings, clearUserSettings, userSettings, companyService, users } = this.props;
    const oldCompanyNotification = companyService.get().userSettings.notification.userCompanyNotification;
    const newCompanyNotification = userSettings.notification.userCompanyNotification;
    const oldNotificationEmty = !oldCompanyNotification.length && newCompanyNotification.length;
    const newNotificationEmty = oldCompanyNotification.length && !newCompanyNotification.length;
    const userId = userSettings.userId;

    updateUserSettings(userSettings);

    if (oldNotificationEmty || newNotificationEmty) {
      const newUsers = updatedUsers(users, userId);

      companyService.toggleUserNotification(newUsers);
    }

    clearUserSettings();

    this.setState({
      userSettingsFlag: !this.state.userSettingsFlag,
    });
  }

  toggleEditing = () => this.setState({
    editable: !this.state.editable,
  });

  toggleReadOnly = () => this.setState({
    readOnly: !this.state.readOnly,
  });

  toggleResponse = () => this.setState({
    waitingResponse: !this.state.waitingResponse,
  });

  handleSaveComment = (comment) => {
    const { item: { Id } } = this.props;

    this.toggleResponse();
    this.props.onSaveComment(Id, comment).then(() => {
      this.toggleResponse();
      this.toggleEditing();
    });
  };

  renderCommentTitle = () => (
    <div className={ styles['dialog_comment-title'] }>Комментарий Intercom для пользователя: {this.props.item.RegistrationName}</div>
  );

  renderCommentEditDialog = comment => (
    <Dialog onClick={ this.toggleEditing } width='650px'>
      <div className={ styles.dialog_comment }>
        { this.renderCommentTitle() }
        <CommentComponent
          value={ comment || '' }
          placeholder={ COMMENTS.IntercomComment.INPUTCOMMENT }
          waitingResponse={ this.state.waitingResponse }
          position={ COMPONENTS.COMMENTCOMPONENT.POSITION.BOTTOM }
          onSave={ this.handleSaveComment }
          onClose={ this.toggleEditing }
        />
      </div>
    </Dialog>
  );

  renderCommentDialog = comment => (
    <Dialog showCloseButton onClick={ this.toggleReadOnly } width='650px'>
      <div className={ styles.dialog_comment }>
        { this.renderCommentTitle() }
        <div className={ styles['dialog_comment-read'] } >{comment}</div>
      </div>
    </Dialog>
  );

  renderText = (comment) => {
    if (comment.length > 20) {
      return (
        <div className={ styles['comment-text'] }>
          <div>{comment.substring(0, 20)}</div>
          <div className={ `${styles['comment-link-text']} sw-tooltip-wrapper` } onClick={ this.toggleReadOnly }>
            <span>...</span>
            <Tooltip
              position={ COMPONENTS.TOOLTIP.POSITION.TOP }
            >
              <div className={ styles['comment-tooltip-text'] }>{ LABELS.TOOLTIPCOMMENT }</div>
            </Tooltip>
          </div>
        </div>
      );
    }

    return <div>{comment}</div>;
  };

  renderComment = (comment) => {
    const { editable, readOnly } = this.state;

    const editDialogHtml = editable && this.renderCommentEditDialog(comment);
    const dialogHtml = readOnly && this.renderCommentDialog(comment);
    const textHtml = comment && comment.length ? this.renderText(comment) : COMMENTS.IntercomComment.NOCOMMENTTEXT;

    return (
      <div className={ `${styles['col-1-4']} ${styles.row_for_user_item}` }>
        { textHtml }
        <span
          className={ styles.edit_user }
          onClick={ this.toggleEditing }
        >
          <i className='material-icons'>mode_edit</i>
        </span>
        { dialogHtml }
        { editDialogHtml }
      </div>
    );
  };

  renderSendUserDocuments = (admin, waitingSendUserDoc) => {
    const { item, onSendUserDoc, adminChange } = this.props;
    const { Id, SendDoc } = item;
    const showTooltip = !adminChange ? (<Tooltip
      position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
    >
      { LABELS.TOOLTIP.SEND_DOC }
    </Tooltip>) : null;

    const showCheckbox = admin && (
      <Checkbox
        value={ SendDoc }
        onChange={ () => onSendUserDoc(item, Id, !SendDoc) }
        disabled={ !adminChange }
        loading={ waitingSendUserDoc }
      />
    );

    return (
      <div className={ `${styles['col-1-9']} ${styles.row_for_user_item}` }>
        <div className={ `sw-tooltip-wrapper ${styles.notification}` }>
          <span>
            { showCheckbox }
            { showTooltip }
          </span>
        </div>
      </div>
    );
  };

  renderAddIcon = ({ EditRightsLevelIsAvailable, IsPrimaryAccount }) => {
    const unconfirmedUser = !EditRightsLevelIsAvailable && IsPrimaryAccount;

    if (!(EditRightsLevelIsAvailable || IsPrimaryAccount) || unconfirmedUser) return null;

    let typeIcon;
    let tooltipText;

    if (EditRightsLevelIsAvailable && IsPrimaryAccount) {
      typeIcon = TYPEICONLIST.VERIFIEDUSER.TYPEICON;
      tooltipText = TYPEICONLIST.VERIFIEDUSER.TOOLTIPTEXT;
    } else if (EditRightsLevelIsAvailable) {
      typeIcon = TYPEICONLIST.SUPERVISOR.TYPEICON;
      tooltipText = TYPEICONLIST.SUPERVISOR.TOOLTIPTEXT;
    }

    return (
      <span>
        <i className={ `material-icons ${styles.icons}` } >
          { typeIcon }
        </i>
        <Tooltip
          position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
        >
          { tooltipText }
        </Tooltip>
      </span>
    );
  };

  renderEditUser = ({ EditRightsLevelIsAvailable, IsPrimaryAccount }) =>
    EditRightsLevelIsAvailable && IsPrimaryAccount && (
      <span
        className={ styles.edit_user }
        onClick={ this.props.onEditUserDialog }
      >
        <i className='material-icons'>mode_edit</i>
      </span>
  );

  renderStarUser = rulesForStarsIcon => (rulesForStarsIcon ? (
    <i className={ `material-icons ${styles.icon_star}` } >star</i>) : (
      <i className={ `material-icons ${styles.icon_star}` } />));

  renderEditUserDialog = (item) => {
    const { userActiveData, onEditUserDialog, onChangeUsersData, onSaveUserData } = this.props;
    const { showEditUserDialog, Id } = userActiveData;
    const isActiveDataUser = Id === item.Id;

    return showEditUserDialog && isActiveDataUser && (
      <UserEditDialog
        item={ userActiveData }
        onChange={ onChangeUsersData }
        onClose={ onEditUserDialog }
        onSave={ onSaveUserData }
      />
    );
  };

  renderUserChangeOfRights = (label, onClick, id, status, styleButton, styleContainer) => {
    const { userActiveData: { loadingButtons } } = this.props;
    const { status: loadingStatus, loading } = loadingButtons;
    const isLoading = loadingStatus === status && loading;

    return (
      <div className={ styleContainer }>
        <AjaxButton
          className={ styleButton }
          label={ label }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => onClick(id, status) }
          loading={ isLoading }
        />
      </div>
    );
  }

  renderCancelButtons = (firstLabel, onConfirm, onCancel, idUser, userStatus, styleButton) => {
    const { onSetOfRightsDialog, userActiveData: { showNextStepDisableOfRightsDialog } } = this.props;
    const nextButton = showNextStepDisableOfRightsDialog && this.renderUserChangeOfRights(LABELS.BUTTONS_USER_DIALOG.DISABLE_FOREVER, onSetOfRightsDialog, idUser, USERS_STATUS.REJECTED, styles.disable_user_rights);

    return (
      <div className={ styles.button_rights }>
        { this.renderUserChangeOfRights(firstLabel, onConfirm, idUser, userStatus, styleButton) }
        { nextButton }
        { this.renderUserChangeOfRights(LABELS.BUTTONS_USER_DIALOG.CANCEL, onCancel, '', '', styleButton) }
      </div>
    );
  }

  renderSetOfRightsDialog = (
    isActiveDataUser,
    width,
    showDialog,
    isAdmin,
    firstText,
    secondText,
    onConfirm,
    userStatus,
    firstLabel,
    styleButton
  ) => {
    const { userActiveData: { Id: idUser }, onCancelOfRightsDialog } = this.props;

    return showDialog && isActiveDataUser && isAdmin && (
      <Dialog onClick={ onCancelOfRightsDialog } width={ width }>
        <div className={ styles.user_transfer_rights_dialog }>
          <div className={ styles.user_transfer_text }>
            { firstText }
            <br />
            { secondText }
          </div>
          { this.renderCancelButtons(firstLabel, onConfirm, onCancelOfRightsDialog, idUser, userStatus, styleButton) }
        </div>
      </Dialog>
    );
  }

  renderAddLink = ({ RegistrationStatusStr, RegistrationUrl }) => {
    if (RegistrationStatusStr !== STATUS.PENDING) return null;

    return (
      <span>
        <i
          className={ `material-icons ${styles.icons}` }
          onClick={ () => this.props.onClickRegistrationLink(RegistrationUrl) }
        >
          { TYPEICONLIST.REGISTERURL.TYPEICON }
        </i>
        <Tooltip
          position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
        >
          { TYPEICONLIST.REGISTERURL.TOOLTIPTEXT }
        </Tooltip>
      </span>
    );
  };

  renderErrorDialog = (isActiveDataUser) => {
    const { onSaveErrorSetRightUser, userActiveData } = this.props;
    const { saveErrorSetRightUser, sendUserDocFail } = userActiveData;

    return (saveErrorSetRightUser || sendUserDocFail) && isActiveDataUser && (
      <NoResult
        onClose={ onSaveErrorSetRightUser }
        message={ LABELS.ERROR_USER_SAVE }
      />
    );
  };

  renderUserSettingsDialog = () => {
    const {
      item,
      isActiveUser,
      userSettings,
      companies,
      userCompanies,
      loadingUserSettings,
      onChangeUserSettings,
      accessSettingsChange,
      onChangeArraySettings,
      getDisabledUserSettings,
    } = this.props;
    const settings = isActiveUser ? userSettings : {};

    return (
      <UserSettingsDialog
        item={ item }
        companies={ companies }
        userSettings={ settings }
        userCompanies={ userCompanies }
        isActiveUser={ isActiveUser }
        loading={ loadingUserSettings }
        accessSettingsChange={ accessSettingsChange }
        onClose={ this.handleShowUserSettings }
        onChange={ onChangeUserSettings }
        onChangeDisableUser={ getDisabledUserSettings }
        onChangeArraySettings={ onChangeArraySettings }
        onSave={ this.handleUpdateUserSettings }
      />
    );
  }

  render() {
    const { item, enabled, waitingResponseIds, onClickOnUser, onSendNotification, onShowOfRightsDialog,
      userActiveData, onSetOfRightsDialog, onNextTapDisableOfRights, adminChange,
      getDataUserApproveScheme } = this.props;

    const { RegistrationName, UserName, PhoneNumber, Id, RegistrationStatusStr, IntercomComment,
      EditRightsLevelIsAvailable, IsPrimaryAccount, Email } = item;
    const { showUserRightsDialog, showNextStepDisableOfRightsDialog, showUserApproveDialog, Id: idUser, waitingSendUserDoc } = userActiveData;
    const isActiveDataUser = idUser === Id;

    const rulesForStarsIcon = RegistrationStatusStr === USERS_STATUS.APPROVED || RegistrationStatusStr === USERS_STATUS.PENDING;

    const showStarsEditUser = adminChange && this.renderStarUser(rulesForStarsIcon);

    const starIcon = (
      <span
        className={ styles.active_user }
        onClick={ rulesForStarsIcon ? getDataUserApproveScheme : () => {} }
      >
        { showStarsEditUser }
      </span>
    );

    const commentIntercomHtml = RegistrationStatusStr !== STATUS.PENDING ? this.renderComment(IntercomComment) : null;
    const admin = EditRightsLevelIsAvailable && IsPrimaryAccount;

    const selectLabelForApproveDialog = admin ? TEXT_FOR_DIALOGS.FOR_SCHEME.TO_ADMIN(Email) : this.getNameForUser();

    const userApproveDialogAdmin = this.renderSetOfRightsDialog(
      isActiveDataUser,
      DIALOG_WIDTH.SMALL,
      showUserApproveDialog,
      STEPSRIGHTS.ADMIN,
      TEXT_FOR_DIALOGS.FOR_SCHEME.ATTENTION,
      selectLabelForApproveDialog,
      onShowOfRightsDialog,
      USERS_STATUS.APPROVED,
      LABELS.BUTTONS_USER_DIALOG.PROCEED
    );

    const userSetOfRightsDialog = this.renderSetOfRightsDialog(
      isActiveDataUser,
      DIALOG_WIDTH.SMALL,
      showUserRightsDialog,
      !admin,
      TEXT_FOR_DIALOGS.RIGHTS_ADMIN_MOVED,
      Email,
      onSetOfRightsDialog,
      USERS_STATUS.APPROVED,
      LABELS.BUTTONS_USER_DIALOG.CONFIRM
    );

    const userDisabledOfRightsDialog = this.renderSetOfRightsDialog(
      isActiveDataUser,
      DIALOG_WIDTH.SMALL,
      showUserRightsDialog,
      admin,
      TEXT_FOR_DIALOGS.DISABLED_ADMIN(Email),
      TEXT_FOR_DIALOGS.DO_YOU_CONFIRM,
      onNextTapDisableOfRights,
      '',
      LABELS.BUTTONS_USER_DIALOG.CONFIRM
    );

    const userDisableOfRightsDialogNextStep = this.renderSetOfRightsDialog(
      isActiveDataUser,
      DIALOG_WIDTH.LARGE,
      showNextStepDisableOfRightsDialog,
      admin,
      TEXT_FOR_DIALOGS.SELECT_AN_ACTION,
      Email,
      onSetOfRightsDialog,
      USERS_STATUS.DISABLED,
      LABELS.BUTTONS_USER_DIALOG.DISABLE_RECOVERABLE,
      styles.disable_user_rights
    );

    return (
      <div className={ `${styles['user-list']}` } >
        <div className={ `${styles['col-1-7']} ${styles.row_for_user_item} ${styles.user_item_display}` }>
          { starIcon }
          <span
            className={ `sw-tooltip-wrapper ${styles.clickable} ${styles['user-name']}` }
            onClick={ () => onClickOnUser(item) }
          >
            { RegistrationName }
            { this.renderAddIcon(item) }
          </span>
          { this.renderEditUser(item) }
          <span className={ `sw-tooltip-wrapper ${styles.clickable} ${styles['user-name']}` } >
            { this.renderAddLink(item) }
          </span>
        </div>
        <div className={ `${styles['col-1-5']} ${styles.row_for_user_item}` }>{ UserName }</div>
        <div className={ `${styles['col-1-7']} ${styles.row_for_user_item}` }>{ PhoneNumber }</div>
        <div className={ `${styles['col-1-9']} ${styles.row_for_user_item}` }>
          <div className={ `sw-tooltip-wrapper ${styles.notification}` }>
            <span>
              <Checkbox
                value={ enabled[Id] }
                onChange={ () => onSendNotification(Id) }
                loading={ waitingResponseIds.includes(Id) }
              />
              <Tooltip
                position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
              >
                { LABELS.TOOLTIP.COMMENT }
              </Tooltip>
            </span>
          </div>
        </div>
        { this.renderSendUserDocuments(admin, waitingSendUserDoc) }
        { this.renderEditUserDialog(item) }
        { userApproveDialogAdmin }
        { userSetOfRightsDialog }
        { userDisabledOfRightsDialog }
        { userDisableOfRightsDialogNextStep }
        <div className={ `${styles['col-1-9']} ${styles.row_for_user_item}` }>
          <div className={ `sw-tooltip-wrapper ${styles.notification}` }>
            <span>
              <button onClick={ this.handleShowUserSettings } className={ styles.user_settings_button } >
                <i className='material-icons'>settings</i>
              </button>
            </span>
          </div>
        </div>
        { commentIntercomHtml }
        { this.renderErrorDialog(isActiveDataUser) }
        { this.state.userSettingsFlag && this.renderUserSettingsDialog() }
      </div>
    );
  }
}

export default UserItem;
